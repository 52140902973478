<template>
  <Card
    class="w-full"
    centerContent
    :title="$t('health_actions.details.to_do')"
    icon="Checklist"
    :subtitle="toDoSubtitle"
    accentColor="sky"
  >
    <div
      v-if="!questionnaireToDoIsComplete || questionnaireToDoStarted"
      class="flex flex-col items-center justify-center w-full p-20"
    >
      <div
        class="flex items-center justify-center rounded-full h-60 w-60 bg-sky-100"
      >
        <Notes class="h-35 text-sky-500" />
      </div>

      <HMButton
        rounded="full"
        class="mt-30 w-300"
        :to="questionnaireToDoQuestionnaireUrl"
      >
        {{ ctaText }}
      </HMButton>
    </div>
    <div v-else class="flex flex-col items-center justify-center w-full p-20">
      <CheckCircle class="h-25 text-sky-500" />
      <Body class="font-bold">{{ title }}</Body>
      <Body class="text-sm">
        {{ $t('health_actions.details.completed_on', [questionnaireToDoLastTaken]) }}
      </Body>
    </div>
  </Card>
</template>

<script>
import { Notes, CheckCircle } from '@assets/svgs/index'
import { Card } from '@components/common/card/index'
import healthActionMixin from '@platform-shared/mixins/health-actions/healthActionMixin.js'

export default {
  components: {
    Card,
    Notes,
    CheckCircle,
  },
  mixins: [healthActionMixin],
  computed: {
    ctaText() {
      if (this.questionnaireToDoStarted) {
        return this.$t('hra.details.continue')
      } else {
        return this.$t('hra.details.start')
      }
    },
  },
}
</script>

<style></style>
