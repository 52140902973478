<template>
  <RadialProgressBar
    class="transform rotate-180"
    :diameter="130"
    :strokeWidth="6"
    :innerStrokeWidth="6"
    startColor="#FF8A00"
    stopColor="#FFD057"
    innerStrokeColor="#E6E6E6"
    :completed-steps="percentageComplete"
    :total-steps="100"
  >
    <div
      class="flex items-center justify-center transform rotate-180 rounded-full h-80 w-80"
      :class="`bg-sky-100`"
    >
      <Icon :iconClass="iconClass" />
    </div>
  </RadialProgressBar>
</template>

<script>
import healthActionMixin from '@platform-shared/mixins/health-actions/healthActionMixin.js'
import RadialProgressBar from 'vue-radial-progress'
import { mapGetters } from 'vuex'
import { HEALTH_ACTION_CATALOG_ICON_CLASSES as catalogIcons } from '@platform-shared/constants'

export default {
  components: { RadialProgressBar },
  mixins: [healthActionMixin],
  computed: {
    ...mapGetters('healthActions', ['getHealthActionCatalogByHealthActionId']),

    healthActionCatalogCode() {
      return this.getHealthActionCatalogByHealthActionId(this.$route.params.id)
        ?.code
    },

    iconClass() {
      return `${
        this.healthActionCatalogCode &&
        catalogIcons[this.healthActionCatalogCode]?.web
          ? catalogIcons[this.healthActionCatalogCode].web
          : 'fas fa-clipboard-list'
      } text-5xl text-sky-500`
    },
  },
}
</script>

<style></style>
