<template>
  <div
    v-if="supportsMemberAttestation"
    class="flex justify-center"
    :class="{ 'w-full': showingForm }"
  >
    <div v-if="memberAttestedThisPeriod(memberId)" class="space-y-8">
      <Body>
        <span class="font-bold">
          {{ $t('health_actions.attestation.logged') }}
        </span>
        {{ mostRecentAttestation(memberId) | FD }}
      </Body>
      <Body class="px-5 font-bold bg-grey-200">
        {{ $t('health_actions.attestation.pending') }}
      </Body>
    </div>
    <div v-else-if="!showingForm" class="text-center">
      <HMButton rounded="full" @click="showingForm = true">
        {{ attestationAction }}
      </HMButton>
    </div>
    <div v-else class="w-full">
      <AttestationSubmitted v-if="successfulSubmission" />
      <AttestationFormWrapper
        :attestationConfig="attestationConfig"
        :currentCompliancePeriod="currentCompliancePeriod"
        :instancePeriod="instancePeriod"
        :healthActionId="healthActionId"
        :dependent="dependent"
        @complete="successfulSubmission = true"
      />
    </div>
  </div>
</template>

<script>
import healthActionMixin from '@platform-shared/mixins/health-actions/healthActionMixin.js'
import AttestationSubmitted from './AttestationSubmitted'
import AttestationFormWrapper from './AttestationFormWrapper.vue'

export default {
  components: {
    AttestationSubmitted,
    AttestationFormWrapper,
  },
  mixins: [healthActionMixin],
  props: {
    memberId: {
      type: Number,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {
      showingForm: false,
      successfulSubmission: false,
    }
  },
  computed: {
    dependent() {
      return this.memberId ? this.memberToAttest(this.memberId) : null
    },
    instancePeriod() {
      return {
        startDate: this.instanceStartDate,
        endDate: this.instanceEndDate,
      }
    },
  },
}
</script>
