import { QUESTION_TYPES } from '@platform-shared/constants'
import { mapGetters, mapActions } from 'vuex'
import flow from 'lodash/fp/flow'
import getOr from 'lodash/fp/getOr'
import map from 'lodash/fp/map'
import groupBy from 'lodash/fp/groupBy'
import orderBy from 'lodash/fp/orderBy'
import find from 'lodash/fp/find'
import _head from 'lodash/head'
import _get from 'lodash/get'
import _map from 'lodash/map'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import questionnaireToDoMixin from '../health-actions/questionnaireToDoMixin'

const questionnaireToDoResults = {
  mixins: [questionnaireToDoMixin],
  computed: {
    ...mapGetters('member', ['preferredLanguageCd']),
    ...mapGetters('questionnaire', ['isLoading', 'resultsQuestionnaireByType']),
    questionnaireToDo() {
      return this.resultsQuestionnaireByType(this.questionnaireType)
    },
    yearlyCompletions() {
      // take the most recent completion of a given year so there
      // will only ever be shown at most one completion per year
      return flow(
        getOr([], 'allCompletions'),
        map((completion) => ({
          year: format(parseISO(completion.endTimestamp), 'yyyy'),
          completion,
        })),
        groupBy((item) => item.year),
        map((item) => _head(item).completion),
        orderBy('endTimestamp', 'desc')
      )(this.questionnaireToDo)
    },
    completedOn() {
      const timestamp = _get(this.yearlyCompletions, [0, 'endTimestamp'])
      return format(parseISO(timestamp), 'MMMM do, yyyy')
    },
    questions() {
      return flow(
        getOr([], 'orderedQuestions'),
        map((questionCd) => {
          const yearlyAnswersForQuestion = this.getYearlyAnswersForQuestion(
            questionCd
          )
          return {
            question: this.getQuestionByQuestionCd(questionCd),
            answers: _get(yearlyAnswersForQuestion[0], 'answers', []),
            yearlyAnswers: yearlyAnswersForQuestion,
          }
        })
      )(this.questionnaireToDo)
    },
    questionnaireLanguage() {
      const preferredLanguageCode = this.preferredLanguageCd

      return preferredLanguageCode.toLowerCase()
    },
  },
  methods: {
    ...mapActions('questionnaire', ['getQuestionnairesByType']),
    getQuestionByQuestionCd(questionCd) {
      const question = _get(this.questionnaireToDo, [
        'questions',
        questionCd,
        'question',
      ])

      return _get(
        question,
        this.questionnaireLanguage,
        _get(question, 'en', '')
      )
    },
    getQuestionTypeByQuestionCd(questionCd) {
      return _get(this.questionnaireToDo, ['questions', questionCd, 'type'], '')
    },
    getAnswerFromAnswerOptions(questionCd, answer) {
      return flow(
        getOr([], ['questions', questionCd, 'answerOptions']),
        find({ id: answer }),
        getOr(getOr('', ['option', 'en']), [
          'option',
          this.questionnaireLanguage,
        ])
      )(this.questionnaireToDo)
    },
    getAnswersForYearCompletionByQuestion(completion, questionCd) {
      return flow(
        getOr([], ['answers', questionCd]),
        map((questionAnswer) => {
          return questionAnswer.text
            ? this.getQuestionTypeByQuestionCd(questionCd) ===
              QUESTION_TYPES.DATE_FIELD // format date if question type is date_field
              ? format(parseISO(questionAnswer.answer), 'MMMM do, yyyy')
              : questionAnswer.answer
            : this.getAnswerFromAnswerOptions(questionCd, questionAnswer.answer) // look up answer from options
        })
      )(completion)
    },
    getYearlyAnswersForQuestion(questionCd) {
      return _map(this.yearlyCompletions, (completion) => ({
        year: format(parseISO(completion.endTimestamp), 'yyyy'),
        answers: this.getAnswersForYearCompletionByQuestion(
          completion,
          questionCd
        ),
      }))
    },
  },
}

export default questionnaireToDoResults
