<template>
  <div class="w-full max-w-4xl">
    <Announcements />
    <div class="w-full max-w-4xl py-20 bg-white">
      <h2 class="mt-5 mb-20">{{ $t('common.welcome_back', [firstName]) }}</h2>
      <RewardsOverviewCard v-if="showRewards && hasBasketRewards" />
      <DirectIncentivesOverviewCard
        v-if="showRewards && hasDirectIncentives"
        :class="showRewards && hasDirectIncentives ? 'mt-16' : 'mt-0'"
      />
      <TrackerCarousel v-if="showTrackingCarousel" class="mt-16" />
      <HealthActionCardContainer :preventHraModal="showPhaWelcome" />
      <Modal v-if="showPhaWelcome" :active="showPhaWelcome">
        <WelcomeModal />
      </Modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import WelcomeModal from './pha-welcome/WelcomeModal'
import TrackerCarousel from './TrackerCarousel'
import Modal from '@components/common/Modal'
import Announcements from './Announcements'
import { FEATURES } from '@platform-shared/constants'
import RewardsOverviewCard from '../rewards/RewardsOverviewCard'
import DirectIncentivesOverviewCard from '../rewards/DirectIncentivesOverviewCard'
import HealthActionCardContainer from './HealthActionCardContainer.vue'

export default {
  components: {
    Announcements,
    TrackerCarousel,
    Modal,
    WelcomeModal,
    RewardsOverviewCard,
    DirectIncentivesOverviewCard,
    HealthActionCardContainer,
  },
  computed: {
    ...mapGetters('member', ['firstName', 'showPhaWelcome']),
    ...mapGetters('client', ['hasFeature']),
    ...mapGetters('tracking', ['trackers']),
    ...mapGetters('rewards', ['hasBasketRewards', 'hasDirectIncentives']),
    showRewards() {
      return this.hasFeature(FEATURES.REWARDS)
    },
    showTrackingCarousel() {
      return (
        this.hasFeature(FEATURES.TRACKING) &&
        this.trackers.length > 0 &&
        this.hasFeature(FEATURES.TRACKING_ON_HOME_SCREEN)
      )
    },
  },
  created() {
    if (this.showRewards) {
      this.initRewards()
    }

    if (this.hasFeature(FEATURES.TRACKING)) {
      this.initTracking()
    }
  },
  methods: {
    ...mapActions('rewards', ['initRewards']),
    ...mapActions('tracking', ['initTracking']),
  },
}
</script>
