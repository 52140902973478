<template>
  <div class="w-full items-center">
    <div
      class="flex justify-between"
      :class="
        (description || (completionCount && earnableAmount)) &&
          'pb-5 border-b border-gray-200'
      "
    >
      <div class="flex items-center space-x-10">
        <h5 class="font-open-bold">{{ title }}</h5>
        <p v-if="completionCount" class="font-semibold text-lg">
          {{ '(' + $t('tracking.steps_completed', completionCount) + ')' }}
        </p>
      </div>
      <p
        v-if="earnableAmount"
        class="inline-block px-6 mt-4 mb-4 font-semibold text-green-600 bg-lime-100 rounded-sm"
      >
        {{
          completed
            ? `${$t(`health_actions.home.earned.dollars`, [earnableAmount])}`
            : `${$t(`health_actions.home.earn.dollars`, [earnableAmount])}`
        }}
      </p>
    </div>
    <p v-if="description" class="text-sm py-10">{{ description }}</p>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    completed: {
      type: Boolean,
      default: false,
    },
    completionCount: {
      type: Array,
      default: null,
    },
    earnableAmount: {
      type: Number,
      default: null,
    },
  },
}
</script>
