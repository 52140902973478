<template>
  <div>
    <SurveyForm :config="formattedAttestationConfig" @onSubmit="handleSubmit" />
    <div class="text-center">
      <FormError v-if="!!error" :text="error" />
    </div>
  </div>
</template>

<script>
import attestationFormMixin from '@platform-shared/mixins/health-actions/attestationFormMixin.js'
import SurveyForm from './SurveyForm.vue'
import FormError from '../../../common/forms/FormError.vue'

export default {
  components: {
    SurveyForm,
    FormError,
  },
  mixins: [attestationFormMixin],
}
</script>
