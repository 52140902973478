<template>
  <div v-if="catalog.assignedHealthActions.length > 0" class="py-20 space-y-10">
    <HealthActionCatalogHeader
      :title="catalog.name"
      :description="catalog.description"
      :earnableAmount="catalog.amount || null"
      :completed="isCatalogComplete"
      :completionCount="catalogCompletionCount"
    />

    <ul class="flex flex-wrap justify-center lg:justify-start">
      <HealthActionCard
        v-for="healthAction in catalog.assignedHealthActions"
        :key="healthAction.id"
        :action="healthAction"
        :completed="healthAction.toDoMembers.length === 0"
        :iconClass="catalogIconDetails"
      />
    </ul>

    <Modal v-if="hraModal" :active="hraModal">
      <HRAModal @close="!hraModal" />
    </Modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import HealthActionCard from '../HealthActionCard.vue'
import HealthActionCatalogHeader from './HealthActionCatalogHeader.vue'
import Modal from '@components/common/Modal'
import HRAModal from '../StartResumeHra'
import {
  HEALTH_ACTION_CODES as healthActionCodes,
  HEALTH_ACTION_CATALOG_ICON_CLASSES as catalogIcons,
} from '@platform-shared/constants'
import getTime from 'date-fns/getTime'
import subWeeks from 'date-fns/subWeeks'
import startOfDay from 'date-fns/startOfDay'
import _find from 'lodash/find'
import healthActionCatalogMixin from '@platform-shared/mixins/health-actions/healthActionCatalogMixin.js'

export default {
  components: {
    HealthActionCard,
    HealthActionCatalogHeader,
    Modal,
    HRAModal,
  },
  mixins: [healthActionCatalogMixin],
  props: {
    preventHraModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hraModal: false,
      fteViewTime: parseInt(localStorage.getItem('fteViewTime')) || 0,
    }
  },
  computed: {
    ...mapGetters('healthActions', ['hasLoaded']),
    shouldShowHRA() {
      if (this.preventHraModal) {
        return false
      }

      if (this.hraModalViewTime === 0) {
        localStorage.setItem('hraModalViewTime', getTime(new Date()))
        return false
      }

      return (
        !!_find(
          this.catalog.assignedHealthActions.filter(
            (h) => h.toDoMembers.length > 0
          ),
          {
            code: healthActionCodes.COMPLETE_HEALTH_QUESTIONNAIRE,
          }
        ) &&
        this.hraModalViewTime < getTime(subWeeks(new Date(), 1)) &&
        this.fteViewTime < getTime(startOfDay(new Date()))
      )
    },
    catalogIconDetails() {
      return this.catalog?.code && catalogIcons[this.catalog.code]?.web
        ? catalogIcons[this.catalog.code].web
        : 'fas fa-clipboard-list'
    },
  },
  watch: {
    hasLoaded(newValue, oldValue) {
      if (newValue && !oldValue && this.shouldShowHRA) {
        this.hraModal = true
      }
    },
  },
}
</script>
