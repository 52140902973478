import { STATES } from '@platform-shared/constants'
import _pick from 'lodash/pick'
import _min from 'lodash/min'
import _get from 'lodash/get'
import _omit from 'lodash/omit'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import subYears from 'date-fns/subYears'
import { mapGetters, mapActions } from 'vuex'
import localizeMixin from '@platform-shared/mixins/localizeMixin'

export default {
  props: {
    attestationConfig: {
      type: Object,
      required: false,
      default: undefined,
    },
    currentCompliancePeriod: {
      type: Object,
      required: true,
    },
    instancePeriod: {
      type: Object,
      required: false,
    },
    healthActionId: {
      type: Number,
      required: true,
    },
    dependent: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  mixins: [localizeMixin],
  data() {
    return {
      source: '~/assets/survey.html',
      states: STATES.map((state) => {
        return { ...state, text: state.display }
      }),
      error: null,
    }
  },
  computed: {
    ...mapGetters('healthActions', ['isSubmittingAttestation']),
    ...mapGetters('member', ['memberCoverageInfo']),
    dependentData() {
      return this.dependent
        ? _pick(this.dependent, ['memberId', 'memberCoverageId'])
        : null
    },
    formattedAttestationConfig() {
      if (!this.attestationConfig.config.pages) return {}

      const pages = this.attestationConfig.config.pages.map((page) => {
        let formattedElements = page.elements
        formattedElements.forEach((element) => {
          if (element.name === 'state') {
            element.choices = this.states
          }

          if (element.name === 'activityCompletionDate') {
            if (
              _get(this.instancePeriod, 'startDate') &&
              _get(this.instancePeriod, 'endDate')
            ) {
              element.min = this.instancePeriod.startDate
              element.max = this.maxDateOfService(this.instancePeriod.endDate)
            } else {
              const minDate = new Date(this.currentCompliancePeriod.startDate)
              element.min = subYears(minDate, 1)
              element.max = this.maxDateOfService(
                _get(this.memberCoverageInfo, 'effectiveMemberCoverage.endDate')
              )
            }
          }
        })
        return { ...page, elements: formattedElements }
      })

      return {
        ...this.attestationConfig,
        config: { ...this.attestationConfig.config, pages: pages },
      }
    },
  },
  methods: {
    ...mapActions('healthActions', ['submitMemberAttestation']),
    maxDateOfService(endDateISOString = new Date().toISOString()) {
      const todayISOString = new Date().toISOString()
      const earlierDate = _min([todayISOString, endDateISOString])
      const formattedDate = format(
        parseISO(earlierDate),
        "yyyy-MM-dd'T'12:59:59'Z'"
      )
      return formattedDate
    },
    handleSubmit(payload) {
      this.error = null
      this.submitMemberAttestation({
        memberAttestation: {
          attestationData: _omit(payload, ['activityCompletionDate']),
          activityCompletionDate: payload.activityCompletionDate,
          dependent: this.dependentData,
        },
        healthActionId: this.healthActionId,
      })
        .then(() => this.$emit('complete'))
        .catch(() => (this.error = this.localize('common.failure')))
    },
  },
}
