import { mapGetters } from 'vuex'

export default {
  props: {
    catalog: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('healthActions', [
      'hasLoaded',
      'hasAssignedHealthActions',
      'hasHealthActionCatalogs',
      'healthActionCatalogs',
      'uncatalogedHealthActions',
    ]),
    numberCompleted() {
      const assignedHealthActions =
        this.catalog && this.catalog.assignedHealthActions
      return assignedHealthActions
        ? assignedHealthActions.filter((h) => h.toDoMembers.length === 0).length
        : 0
    },
    isCatalogComplete() {
      return this.numberCompleted >= (this.catalog.threshold || 0)
    },
    catalogCompletionCount() {
      if (!this.catalog || !this.catalog.threshold) return null

      return [
        Math.min(this.numberCompleted, this.catalog.threshold),
        this.catalog.threshold,
      ]
    },
  },
}
