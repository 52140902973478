<template>
  <div v-if="!hasLoaded" class="h-200 w-full">
    <Spinner />
  </div>
  <div v-else class="py-20">
    <HealthActionCatalogSection
      v-if="uncatalogedHealthActions.length > 0"
      :catalog="defaultCatalog"
      :preventHraModal="preventHraModal"
    />
    <template v-if="hasHealthActionCatalogs">
      <HealthActionCatalogSection
        v-for="catalog in healthActionCatalogs"
        :key="catalog.id"
        :catalog="catalog"
        :preventHraModal="preventHraModal"
      />
    </template>
    <div
      v-if="hasLoaded && !hasAssignedHealthActions"
      class="p-20 text-center flex flex-col items-center space-y-6"
    >
      <Icon
        class="text-5xl rounded-full w-100 h-100 bg-green-100 text-green-500 flex items-center justify-center"
        :iconClass="'fas fa-clipboard-check'"
      />
      <p>
        {{ $t('health_actions.home.all_completed_desc') }}
      </p>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import HealthActionCatalogSection from './health-action-catalog/HealthActionCatalogSection.vue'
import Spinner from '~/components/common/Spinner.vue'

export default {
  components: {
    HealthActionCatalogSection,
    Spinner,
  },
  props: {
    preventHraModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('healthActions', [
      'hasLoaded',
      'hasAssignedHealthActions',
      'hasHealthActionCatalogs',
      'healthActionCatalogs',
      'uncatalogedHealthActions',
    ]),
    defaultCatalog() {
      return {
        name: this.$t('health_actions.home.generic_catalog_title'),
        assignedHealthActions: this.uncatalogedHealthActions,
      }
    },
  },
  created() {
    this.initQuestionnaires()
    this.initHealthActions()
  },
  methods: {
    ...mapActions('healthActions', ['initHealthActions']),
    ...mapActions('questionnaire', ['initQuestionnaires']),
  },
}
</script>
